import React from 'react'
import { useAgency } from '../hooks/use-agency'
import Header from '../components/header'
import Footer from '../components/footer/footer'
import FooterTop from '../components/footer/footer-top'
import SEO from './agency-seo'
import '../assets/scss/screen.scss'

const Layout = ({
  children,
  location,
  pageContext,
  title,
  description,
  ...props
}) => {
  const { agency, settings } = useAgency()
  const locale = pageContext?.locale ?? agency.locale

  const {
    header = (
      <Header
        logoId={agency.photo?.data?.photo_file_id}
        siteTitle={agency.name}
        currentPath={location.pathname}
        locale={locale}
      />
    ),
  } = props

  return (
    <div className="c-home">
      <SEO title={title} description={description} />
      {header}
      {children}
      <FooterTop settings={settings} agency={agency} locale={locale} />
      <Footer settings={settings} />
    </div>
  )
}

export default Layout
