import React from 'react'
import Layout from '../../components/layout'
import { FormattedMessage } from 'react-intl'
import H2 from '../../theming/H2'
import ContactSuccess from '../../components/contact-success'

export default (props) => {
  return (
    <Layout {...props}>
      <div className="c-row c-row--beta">
        <div className="o-container">
          <div className="o-grid__item u-3-of-5-bp4 u-push-1-of-5-bp4">
            <H2 className="section-title generalContactRequest">
              <FormattedMessage id="page.contact" />
            </H2>
            <ContactSuccess />
          </div>
        </div>
      </div>
    </Layout>
  )
}
