import React from 'react'
import FooterNavigation from './footer-navigation'
import AgencyLocation from './agency-location'
import SocialChannels from './social-channels'
import ContactBtn from './contact-btn'
import H3 from '../../theming/H3'

export default ({ settings, agency, locale }) => {
  return (
    <nav className="c-row c-row--beta c-row--footer">
      <div className="o-container">
        <div className="o-grid o-grid--gutter o-grid--spaced-vertical">
          <div className="o-grid__item">
            <H3>{agency.name}</H3>
          </div>
          <div className="o-grid__item u-1-of-3-bp3 print-display-none">
            <FooterNavigation locale={locale} settings={settings} />
          </div>
          <div className="o-grid__item u-1-of-3-bp3">
            <AgencyLocation settings={settings} />
          </div>
          <div className="o-grid__item u-1-of-3-bp3 print-display-none">
            <SocialChannels settings={settings} />
            <ContactBtn />
          </div>
        </div>
      </div>
    </nav>
  )
}
